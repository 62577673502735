<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import {getLangMessage} from "@/api/lang";
import i18n from "@/lang";
import { getLanguage } from '@/lang'

export default {
  name: 'App',
  mounted() {
    let lang = getLanguage()
    getLangMessage(lang).then(res =>{
      let a = eval(res.data)
      i18n.setLocaleMessage(lang, a)
    })
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif
}

.content_div {
  /*height: calc(100vh - 100px);*/
  background-color: white;
  padding: 20px
}
.el-table-header{
  /*background-color: #f8f8f9;*/
}
</style>
