import {login, logout, getInfo, getUserInfo} from '@/api/auth/user'
import {getToken, setToken, removeToken} from '@/utils/auth'
import router, {resetRouter} from '@/router'

const state = {
    token: getToken(),
    name: '',
    menus: [],
    userDepId: '',
    userType: '',
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_MENUS: (state, menus) => {
        state.menus = menus
    },
    SET_USERDEPID: (state, userDepId) => {
        state.userDepId = userDepId
    },
    SET_USERTYPE: (state, userType) => {
        state.userType = userType
    },
}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {username, password} = userInfo
        return new Promise((resolve, reject) => {
            login(userInfo).then(res => {
                commit('SET_TOKEN', res)
                setToken(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({commit}) {
        return new Promise((resolve, reject) => {
            getUserInfo().then(res => {
                // const {code} = res
                // if (code !== 200) {
                //     reject('验证失败，请重新登录！')
                // }
                const {userName, roles, userType,userDepId} = res
                commit('SET_NAME', userName)
                commit('SET_ROLES', roles)
                commit('SET_USERTYPE', userType)
                commit('SET_MENUS', [])
                commit('SET_USERDEPID', userDepId)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {

            }).catch(error => {
                reject(error)
            }).finally(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                removeToken()
                resetRouter()
                dispatch('tagsView/delAllViews', null, {root: true})
                resolve()
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    // dynamically modify permissions
    async changeRoles({commit, dispatch}, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const {roles} = await dispatch('getInfo')

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, {root: true})
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, {root: true})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
