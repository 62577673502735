import request from '@/utils/request'

export function getLang() {
    return request({
        url: '/lang/index.js',
        method: 'get'
    })
}

export function getLangMessage(lang) {
    return request({
        url: '/lang/' + lang +'.js',
        method: 'get'
    })
}
