import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/layout'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('../views/login/index'),
        meta: {title: '登录', icon: 'home', affix: false},
        hidden: true
    },

    {
        path: '/',
        name: 'layout',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                component: () => import('@/views/dashboard/index'),
                name: 'home',
                code: '0',
                meta: {title: 'home', icon: 'home', affix: true, menuCode: '0'}
            }
        ]
    },
    /*{
        path: '/codecProtocol',
        component: Layout,
        //redirect: '/dashboard',
        children: [
            {
                path: '/codecProtocol',
                component: () => import('@/views/encodingCenter/codecProtocol'),
                name: 'codecProtocols',
                meta: {title: '编解码协议', icon: 'encode', affix: false}
            },

        ]
    },
    {
        path: '/customer',
        component: Layout,
        //redirect: '/dashboard',
        children: [
            {
                path: '/customer',
                component: () => import('@/views/encodingCenter/tagCustomer'),
                name: 'customer',
                meta: {title: '客户管理', icon: 'customer', affix: false}
            },

        ]
    },
    {
        path: '/products',
        component: Layout,
        children: [
            {
                path: '/products',
                component: () => import('@/views/encodingCenter/products'),
                name: 'customer',
                meta: {title: '产品信息', icon: 'products', affix: false}
            },
        ]
    },
    {
        path: '/encodeRecord',
        component: Layout,
        children: [
            {
                path: '/encodeRecord',
                component: () => import('@/views/encodingCenter/encodeRecord'),
                name: 'encodeRecord',
                meta: {title: '编码记录', icon: 'computer', affix: false}
            },
        ]
    },
    {
        path: '/bindRecord',
        component: Layout,
        children: [
            {
                path: '/bindRecord',
                component: () => import('@/views/encodingCenter/bindRecord'),
                name: 'customer',
                meta: {title: '绑定记录', icon: 'bind', affix: false}
            },
        ]
    },*/
    /*{
        path: '/baseinfo',
        component: Layout,
        meta: {title: '基础信息', icon: 'baseinfo', affix: false},
        children: [
            {
                path: 'productsinfo',
                component: () => import('@/views/baseinfo/product'),
                name: 'productsinfo',
                meta: {title: '商品信息', icon: 'products', affix: false}
            },
            {
                path: 'productspic',
                component: () => import('@/views/baseinfo/productspic'),
                name: 'productspic',
                meta: {title: '商品图片', icon: 'productspic', affix: false}
            },
            {
                path: 'department',
                component: () => import('@/views/baseinfo/department'),
                name: 'department',
                meta: {title: '部门管理', icon: 'department', affix: false}
            },
            {
                path: 'vendor',
                component: () => import('@/views/baseinfo/vendor'),
                name: 'vendor',
                meta: {title: '供应商', icon: 'vendor', affix: false}
            },
            {
                path: 'customer',
                component: () => import('@/views/baseinfo/customer'),
                name: 'customer',
                meta: {title: '客户管理', icon: 'customer', affix: false}
            }
        ]
    },
    {
        path: '/system',
        component: Layout,
        meta: {title: '系统管理', icon: 'settings', affix: false},
        children: [
            {
                path: 'role',
                component: () => import('@/views/system/role'),
                name: 'role',
                meta: {title: '角色管理', icon: 'role', affix: false}
            },
            {
                path: 'user',
                component: () => import('@/views/system/user'),
                name: 'userManage',
                meta: {title: '用户管理', icon: 'user', affix: false}
            },
            {
                path: 'codecProtocol',
                component: () => import('@/views/system/codecProtocol'),
                name: 'codecProtocol',
                meta: {title: '编解码设置', icon: 'encodec', affix: false}
            },
            {
                path: 'dic',
                component: () => import('@/views/dic/dicitem'),
                name: 'dicItemManage',
                meta: {title: '字典管理', icon: 'dic', affix: false}
            }
        ]
    },
    {
        path: '/device',
        component: Layout,
        meta: {title: '设备', icon: 'device', affix: false},
        children: [
            {
                path: 'version',
                component: () => import('@/views/device/swVersion'),
                name: 'version',
                meta: {title: '版本管理', icon: 'version', affix: false}
            },
            {
                path: 'deviceManage',
                component: () => import('@/views/device/devicemanage'),
                name: 'deviceManage',
                meta: {title: '设备管理', icon: 'devicemanage', affix: false}
            }
        ]
    },
    {
        path: '/encodingCenter',
        component: Layout,
        meta: {title: '编码中心', icon: 'encode', affix: false},
        children: [
            {
                path: '/encodeRecord',
                component: () => import('@/views/encodingCenter/encodeRecord'),
                name: 'encodeRecord',
                meta: {title: '编码记录', icon: 'computer', affix: false}
            },
            {
                path: '/bindRecord',
                component: () => import('@/views/encodingCenter/bindRecord'),
                name: 'customer',
                meta: {title: '绑定记录', icon: 'bind', affix: false}
            }
        ]
    },
    {
        path: '/DC',
        component: Layout,
        meta: {title: '总仓', icon: 'warehouse', affix: false},
        children: [
            {
                path: 'purchasein',
                component: () => import('@/views/order/purchasein'),
                name: 'purchasein',
                meta: {title: '采购入库', icon: 'purchasein', affix: false}
            },
            {
                path: 'producein',
                component: () => import('@/views/order/producein'),
                name: 'producein',
                meta: {title: '生产入库', icon: 'producein', affix: false}
            },
            {
                path: 'otherin',
                component: () => import('@/views/order/otherin'),
                name: 'otherin',
                meta: {title: '其它入库', icon: 'otherin', affix: false}
            },
            {
                path: 'saleout',
                component: () => import('@/views/order/saleout'),
                name: 'saleout',
                meta: {title: '销售出库', icon: 'saleout', affix: false}
            },
            {
                path: 'returnin',
                component: () => import('@/views/order/returnin'),
                name: 'saleout',
                meta: {title: '退货入库', icon: 'return', affix: false}
            }
        ]
    },
    {
        path: '/shop',
        component: Layout,
        meta: {title: '零售', icon: 'retail', affix: false},
        children: [
            {
                path: 'retailin',
                component: () => import('@/views/order/retailin'),
                name: 'retailin',
                meta: {title: '门店收货', icon: 'retailin', affix: false}
            },
            {
                path: 'retailreturn',
                component: () => import('@/views/order/retailreturn'),
                name: 'producein',
                meta: {title: '门店退货', icon: 'retailreturn', affix: false}
            },
            {
                path: 'salesslip',
                component: () => import('@/views/order/salesslip'),
                name: 'salesslip',
                meta: {title: '门店销售', icon: 'sales', affix: false}
            },
            {
                path: 'salesreturn',
                component: () => import('@/views/order/salesreturn'),
                name: 'salesreturn',
                meta: {title: '销售退货', icon: 'salesreturn', affix: false}
            }
        ]
    },*/
    // {
    //     path: '/showdemo',
    //     component: Layout,
    //     meta: {title: '演示', icon: 'retail', affix: false},
    //     children: [
    //         {
    //             path: 'pf',
    //             component: () => import('@/views/test/productFlow'),
    //             name: 'pf',
    //             meta: {title: '商品流通查询', icon: 'retailin', affix: false}
    //         },
    //         {
    //             path: 'bord',
    //             component: () => import('@/views/test/bord'),
    //             name: 'bord',
    //             meta: {title: '看板', icon: 'retailreturn', affix: false}
    //         }
    //     ]
    // },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    // 404 page must be placed at the end !!!
    // { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    originalPush.call(this, location).catch(err => err)
}
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
export default router
