<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <svg-icon class-name="international-icon" icon-class="language"/>
    </div>
    <el-dropdown-menu slot="dropdown">

      <el-dropdown-item v-for="l in lang" :disabled="language=== l.value" :command="l.value">
        {{ l.text }}
      </el-dropdown-item>

<!--      <el-dropdown-item :disabled="language==='zh'" command="zh">-->
<!--        中文-->
<!--      </el-dropdown-item>-->
<!--      <el-dropdown-item :disabled="language==='en'" command="en">-->
<!--        English-->
<!--      </el-dropdown-item>-->
<!--      <el-dropdown-item :disabled="language==='es'" command="es">-->
      <!--        Español-->
      <!--      </el-dropdown-item>-->
      <!--      <el-dropdown-item :disabled="language==='ja'" command="ja">-->
      <!--        日本語-->
      <!--      </el-dropdown-item>-->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import {getLang, getLangMessage} from "@/api/lang";

export default {
  data(){
    return{
      lang: [{text: '中文', value: 'zh'},{text: 'English', value: 'en'}]
    }
  },
  created() {
    getLang().then((res)=>{
      this.lang = eval(res.data)
    })
  },
  computed: {
    language() {
      return this.$store.getters.language
    }
  },
  methods: {
    handleSetLanguage(lang) {

      getLangMessage(lang).then(res =>{
        let a = eval(res.data)
        this.$i18n.setLocaleMessage(lang, a)


        this.$i18n.locale = lang
        this.$store.dispatch('app/setLanguage', lang)
        this.$message({
          message: 'Switch Language Success',
          type: 'success'
        })

      }).catch(error =>{
        this.$i18n.locale = lang
        this.$store.dispatch('app/setLanguage', lang)
        this.$message({
          message: 'Switch Language Success',
          type: 'success'
        })
      })


    }
  }
}
</script>
