export default {
    common: {
        login: 'Login',
        loginVerificationCode: 'Verification Code',
        operation: 'Operation',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        save: 'Save',
        clear: 'Clear',
        submit: 'Submit',
        search: 'Search',
        confirm: 'Confirm',
        cancel: 'Cancel',
        view: 'View',
        tips: 'Tips',
        synchronize:'Synchronize',
        pleaseSel:'Please select',
        inLoading:'Data loading',
        saveSuccessTips: 'Saved successfully!',
        submitSuccessTips: 'Submitted successfully!',
        deleteSuccessTips: 'Delete successfully!',
        contentRequired:'This field is not allowed to be empty',
        contentTooLarge:'The input content is too long',
        operationSuccessTips: 'Operation successfully!',
        seq:'Seq',
        codeDup:'Code duplication',
        nameDup:'Name duplication',
        contentTooLargeTips:'Content is too large',
        operationCanceled:'Operation canceled',
        uploadOptPreTips:'Drag the file here, or',
        uploadOptTailTips:'click upload',
        delConfirmTips: 'Are you sure you want to delete the current record?',
        selFile:'Select file',
        selFileEmptyTips:'Please select the file to upload',
        createTime:'Create time',
        emptyTips:'The content of this field cannot be empty',
        contentCompLimTips:'The content of this field can only be composed of numbers or letters',
        operationFaled:'Operation failed',
        updateTime:'Update time',
        to:'to',
        selStartDateTips:'Please select start date',
        selEndDateTips:'Please select closing date',
        creator:'Creator',
        orderState:'State',
        stateInPending:'Pending execute',
        stateExecuting:'Executing',
        stateCompleted:'Completed',
        export:'Export',
        import:'Import',
        updatePassword:'Update password',
        logout:'Logout',
        oldPassword:'Old password',
        newPassword:'New password',
        confirmPassword:'Confirm password',
        loginAccount:'Account',
        loginPassword:'Password'
    },
    route: {
        '0': 'Home',
        '01': 'Basic definition',
        '0101': 'Product',
        '0102': 'Scene',
        '0103': 'Order',
        '0104': 'Order item',
        '0105': 'Order type',
        '02': 'Basic information',
        '0201': 'Product information',
        '020101':'Search',
        '020102':'Add',
        '020103':'Edit',
        '020104':'Delete',
        '020107':'Synchronize',
        '0202': 'Product image',
        '0203': 'Department manage',
        '020301':'Search',
        '020302':'Add',
        '020303':'Edit',
        '020304':'Delete',
        '020305':'View',
        '020307':'Synchronize',
        '0204': 'Vender',
        '020401':'Search',
        '020402':'Add',
        '020403':'Edit',
        '020404':'Delete',
        '020405':'View',
        '020407':'Synchronize',
        '0205': 'Customer',
        '020501':'Search',
        '020502':'Add',
        '020503':'Edit',
        '020504':'Delete',
        '020505':'View',
        '020507':'Synchronize',
        '03': 'System',
        '0301': 'Role manage',
        '030101':'Search',
        '030102':'Add',
        '030103':'Edit',
        '030104':'Delete',
        '030105':'View',
        '0302': 'User manage',
        '030201':'Search',
        '030202':'Add',
        '030203':'Edit',
        '030204':'Delete',
        '030208':'frozen & recovery',
        '030209':'Reset password',
        '0303': 'Encoding & decoding',
        '0304': 'Dictionary manage',
        '030401':'Search',
        '030402':'Add',
        '030403':'Edit',
        '030404':'Delete',
        '04': 'Device',
        '0401': 'Version manage',
        '040101':'Search',
        '040102':'Add',
        '040103':'Edit',
        '040104':'Delete',
        '0402': 'Device manage',
        '040201':'Search',
        '040204':'Delete',
        '040210':'Approve & refuse',
        '05': 'Encoding Center',
        '0501': 'Encoding Records',
        '050101':'Search',
        '050102':'Add',
        '050103':'Edit',
        '050104':'Delete',
        '050105':'View',
        '050106':'Export',
        '050111':'Encoding',
        '0502': 'Binding Records',
        '050201':'Search',
        '050204':'Delete',
        '050205':'View',
        '050206':'Export',
        '050207':'Import',
        '06':'Inventory statistics',
        'DC': 'Warehouse',
        'CGRK':'Purchase inbound',
        'CGRK01':'Search',
        'CGRK02':'Add',
        'CGRK03':'Edit',
        'CGRK04':'Delete',
        'CGRK05':'Recall',
        'CGRK06':'View',
        'CGRK07':'Export',
        'XSCK':'Sales outbound',
        'XSCK01':'Search',
        'XSCK02':'Add',
        'XSCK03':'Edit',
        'XSCK04':'Delete',
        'XSCK05':'Recall',
        'XSCK06':'View',
        'XSCK07':'Export',
        'QTRK':'Other type inbound',
        'QTRK01':'Search',
        'QTRK02':'Add',
        'QTRK03':'Edit',
        'QTRK04':'Delete',
        'QTRK05':'Recall',
        'QTRK06':'View',
        'QTRK07':'Export',
        'DCINV':'DC inventory',
        'DCINV01':'Search',
        'DCINV04':'Delete',
        'DCINV05':'Recall',
        'DCINV06':'View',
        'DCINV07':'Export',
        'DCINV08':'Update inventory',
        'PRI':'Production inbound',
        'PRI01':'Search',
        'PRI02':'Add',
        'PRI03':'Edit',
        'PRI04':'Delete',
        'PRI05':'Recall',
        'PRI06':'View',
        'PRI07':'Export',
        'SHOP':'Shop',
        'MDSH':'Shop inbound',
        'MDSH01':'Search',
        'MDSH02':'Add',
        'MDSH03':'Edit',
        'MDSH04':'Delete',
        'MDSH05':'Recall',
        'MDSH06':'View',
        'MDSH07':'Export',
        'MDTH':'Shop returns',
        'MDTH01':'Search',
        'MDTH02':'Add',
        'MDTH03':'Edit',
        'MDTH04':'Delete',
        'MDTH05':'Recall',
        'MDTH06':'View',
        'MDTH07':'Export',
        'SI':'Shop inventory',
        'SI01':'Search',
        'SI02':'Add',
        'SI03':'Edit',
        'SI04':'Delete',
        'SI05':'Recall',
        'SI06':'View',
        'SI07':'Export',
        'SI08':'Update inventory',
        'SO00000003':'Shop sales',
        'SO0000000301':'Search',
        'SO0000000302':'Add',
        'SO0000000303':'Edit',
        'SO0000000304':'Delete',
        'SO0000000305':'Recall',
        'SO0000000306':'View',
        'SO0000000307':'Export',
        'SR00000004':'Retail returns',
        'SR0000000401':'Search',
        'SR0000000402':'Add',
        'SR0000000403':'Edit',
        'SR0000000404':'Delete',
        'SR0000000405':'Recall',
        'SR0000000406':'View',
        'SR0000000407':'Export',
        'BF':'Tag scrapping',
        'BF01':'Search',
        'BF02':'Add',
        'BF03':'Edit',
        'BF04':'Delete',
        'BF05':'Recall',
        'BF06':'View',
        'BF07':'Export',
        'MDQTRK':'Other type inbound',
        'MDQTRK01':'Search',
        'MDQTRK02':'Add',
        'MDQTRK03':'Edit',
        'MDQTRK04':'Delete',
        'MDQTRK05':'Recall',
        'MDQTRK06':'View',
        'MDQTRK07':'Export'
        // 补充动态菜单...
    },

    // 定义功能
    tableDef: {
        attrDef:'Attribute definition',
        displayOrder:'Display order',
        fieldName:'Field name',
        fieldDisName:'Field display name',
        fieldType:'Field type',
        fieldLength:'Field length',
        decimalLength:'Decimal length',
        enuItem:'Enumeration items',
        required: 'Required',
        queryItem: 'Query item',
        queryMode: 'Query mode',
        fTypeVarchar:'String',
        fTypeInt:'Integer',
        fTypeDouble:'Double',
        fTypeDatetime:'Datetime',
        fTypeDate:'Date',
        fTypeText:'Text',
        rangeQuery:'Range query',
        fuzzyMatching:'Fuzzy matching',
        accurateQuery:'Accurate Query',
        addField:'Add field',
        editField:'Update field',
        delFieldConfirmTips: 'Are you sure you want to delete the current field?',
        synProductAttr:'Synchronize product fields',
        synProAttrConfirmTips:'Are you sure you want to synchronize product fields?'
    },

    // 场景定义功能
    sceneDef: {
        sceneCode:'Scene code',
        sceneName:'Scene name',
        seqNum:'Sequence number',
        menuImage:'Menu image',
        selImgTips:'Please select a menu image',
        sceneCodeEmptyTips:'Scene code cannot be empty',
        sceneNameEmptyTips:'Scene name cannot be empty',
        codeCompLimTips:'The scene code can only be composed of numbers or letters',
        inputSceneSeq:'Please fill in the scene sequence number',
        addSceneDlgTitle:'Create scene',
        updSceneDlgTitle:'Update scene',
        delConfirmMsg:'This operation will permanently delete this scene. Do you want to continue?'
    },

    // 单据类型定义功能
    orderTypeDef: {
        typeCode:'Type code',
        typeName:'Type name',
        orderCodePre:'Prefix for order',
        menuImage:'Menu image',
        selImgTips:'Please select a menu image',
        optType:'Operation type',
        permitExc:'Permit exceeding',
        scene:'Scene',
        selSceneTips:'Please select the scene',
        relateOrderType:'Related type',
        senderAlias:'Sender alias',
        receiverAlias:'Receiver alias',
        seqNum:'Seq number',
        collectTid:'Collect TID',
        collectUser:'Collect User',
        orderConfig:'Order config',
        orderItemConfig:'Item config',
        printConfig:'Print config',
        stickerTemp:'Stick template',
        packingListTemp:'Packing list template',
        orderPrintTemp:'Order printing template',
        typeCodeEmptyTips:'Type code cannot be empty',
        typeNameEmptyTips:'Type name cannot be empty',
        codeCompLimTips:'The type code can only be composed of numbers or letters',
        inputSceneSeq:'请填写场景顺序号',
        addSceneDlgTitle:'添加场景',
        updSceneDlgTitle:'修改场景',
        delConfirmMsg:'此操作将永久删除本场景, 是否继续?',
        backendList:'Management side list',
        desktopDevList:'Desktop List',
        pdaList:'PDA side list',
        orderAttr:'Order fields',
        displayAttr:'Display fields',
        moveUp:'Move up',
        moveDown:'Move down',
        orderItemAttr:'Order item fields',
        uploadTemplate:'Upload template',
        uploadLimTips:'Can only upload xlsx/xls files, and does not exceed 500kb',
        addTitle:'Add order type',
        updTitle:'Update order type',
        receiveInbound:'Receive inbound',
        allocationInbound:'Allocation inbound',
        deliverOutbound:'Deliver outbound',
        allocationOutbound:'Allocation outbound',
        returnAndInbound:'Return and inbound',
        returnAndOutbound:'Return and outbound',
        relocation:'Relocation',
        labelPurchase:'Label purchase',
        labelCheck:'Label check',
        labelInventory:'Inventory',
        scrap:'Scrap'
    },
    //商品管理
    productManage: {
        addProduct:'Add product information',
        updateProduct:'Update product information'
    },
    // 商品图片上传功能
    productImg: {
        title:'Batch upload of product images',
        uploadTips:'Please package the product images to be uploaded into a single zip file, and folders are not allowed to be included in the zip file',
        impFileName:'Image file',
        uploadLimTips: 'Only zip format files can be uploaded, and the compressed file cannot contain a directory',
        uploadBtnTitle:'Upload'
    },
    //部门管理
    depManage: {
        depCode:'Department code',
        depName:'Department name',
        superiorDep:'Superior department',
        inputQueryTips:'Please enter the code or name',
        channelCode:'Channel code',
        addDep:'Add department',
        updateDep:'Update department',
        viewDep:'View department',
        venderCode:'Vender code',
        venderName:'Vender name',
        venderDesc:'Vender summary',
        addVender:'Add vender',
        updateVender:'Update vender',
        viewVender:'view vender',
        customerCode:'Customer code',
        customerName:'Customer name',
        addCustomer:'Add customer',
        updateCustomer:'Update customer',
        viewCustomer:'View customer',
    },
    // 角色管理
    role: {
        roleName:'Role name',
        roleType:'Role type',
        menuPermissions:'Menu permissions',
        dataPermissions:'Data permissions',
        traceRange:'Trace scope',
        type:'Role type',
        superAdmin:'Super administrator',
        ordinaryRole:'Ordinary role',
        personalPer:'Personal',
        belDepPer:'Belonging department',
        belSubDepPer:'Belonging department & sub department',
        allPer:'All',
        addRole:'Add role',
        updRole:'Update role',
        viewRole:'View role information',
        selDataPerTips:'Please select data permissions',
        selTraceScopeTips:'Please select trace scope',
        roleDup:'Role already exists',
        selMenuPerTips:'Please select menu permissions',
        roleInfoErr:'An error occurred while obtaining role information!'
    },

    // 用户管理
    user: {
        userName:'User name',
        userAccount:'User account',
        dep:'Department',
        role:'Role',
        accountState:'Account state',
        frozen:'Frozen',
        activated:'Activated',
        recovery:'Recovery',
        resetPassword:'Reset password',
        accountDup:'Account already exists',
        addUser:'Add user',
        updateUser:'Update user',
        frozeConfirmTips: 'Are you sure you want to freeze this account?',
        recoveryConfirmTips: 'Are you sure you want to restore this account?',
        resetPasswordConfirmTips:'Are you sure you want to restore the user\'s login password to the default password?'
    },
    //编解码设置
    codecProtocol: {
        protocolSet:'Protocol config',
        protocol:'Protocol',
        customScript:'Custom script',
        customProtocol:'Custom protocol',
        dynamicBinding:'Dynamic binding',
        protocolFile:'Protocol file',
        uploadLimTips: 'Only jar format files can be uploaded, and the compressed file cannot contain a directory',
        encoderCode:'Encoder code',
        decoderCode:'Decoder code',
        inputCodeTips:'Please enter the script content'
    },
    dict:{
        dicTable:'Dictionary table',
        selDicTips:'Please select a dictionary table',
        dicItemCode:'Item code',
        dicItemLabel:'Item label',
        itemSeq:'Seq number',
        extProperty1:'Extended property1',
        extProperty2:'Extended property2',
        parentItem:'Parent item',
        addDicItem:'Add dictionary item',
        updateDicItem:'Update dictionary item',
        viewDicItem:'View dictionary item',
        inputSeqTips:'Please input the dictionary item sequence number',
        seqEmptyTips:'Please input the dictionary item sequence number'
    },

    // 版本管理功能
    version: {
        swName:'Software name',
        matchingDevType:'Adapted device type',
        swVersion:'Version number',
        devCode:'Device code',
        versionDesc:'Version description',
        swDsname:'Version file',
        swFileSize:'Upgrade file size',
        typeWin:'Windows desktop devices',
        typeAndroid:'Android desktop devices',
        typePDA:'PDA',
        typeChannel:'Channel device',
        cashierPlugin:'Cashier plugin',
        allDev:'All device',
        setUpgradeFile:'Set upgrade file',
        targetDevCode:'Target device code',
        targetDevTips:'Empty represents all devices',
        upgradeFile:'Upgrade file',
        uploadTips:'Only zip format files can be uploaded',
        selDevTypeTips:'Please select the device type'
    },
    // 设备管理
    device: {
        devCode: 'Device code',
        devType: 'Device type',
        deployAddr: 'Deploy address',
        lastActiveTime: 'Last active time',
        approvalState: 'Approve state',
        pendingApproval: 'Pending approval',
        approved: 'Approved',
        refused:'Refused',
        agree: 'Agree',
        refuse: 'Refuse',
        softwareVersion:'Software version',
        approveConfirmTips:'Are you sure you want to approve the use of this device?',
        refuseConfirmTips:'Are you sure you want to refuse the use of this device?'
    },

    // 编码中心
    codecCenter: {
        orderCode:'Job code',
        orderDate:'Job date',
        encode:'Encode',
        encodingJob:'Encoding job',
        encodingItem:'Encoding item',
        addItem:'Add item',
        importItem:'Import item',
        sku:'SKU',
        labelQTY:'QTY',
        currentFile:'Current file：',
        notUpload:'Not uploaded',
        firstRowIsHead:'First row is the header',
        skuCover:'Overwrite the same SKU data',
        startRowNum:'Start row num',
        endRowNum:'End row num',
        fieldName:'Attribute name',
        fileColumnName:'Column name in the file',
        uploadLimTips:'Can only upload xlsx/xls files',
        itemEmptyTips:'Item list cannot be empty!',
        skuDuplicated:'SKU duplicated',
        bindOdCode:'Bind order code',
        bindTagList:'Bind tag list',
        epcDuplicated:'EPC duplicated'
    },
    inventory:{
        productName:'Product name',
        unit:'Product unit',
        qty:'QTY',
        total:'Total'
    },
    // 单据
    order: {
        tagList:'Tag list',
        productList:'Product list',
        recall:'Recall',
        updInv:'Update inventory',
        itemList:'Item list',
        aqty:'Actual QTY',
        difqty:'Diff QTY'
    }

}
