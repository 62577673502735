export default {
    common: {
        login: '登 录',
        loginVerificationCode: '验证码',
        operation: '操 作',
        add: '添 加',
        edit: '编 辑',
        delete: '删 除',
        save: '保 存',
        clear: '清 空',
        submit: '提 交',
        search: '搜 索',
        confirm: '确 认',
        cancel: '取 消',
        view: '查 看',
        tips: '提 示',
        synchronize:'同步',
        pleaseSel:'请选择',
        inLoading:'数据加载中',
        saveSuccessTips: '保存成功！',
        submitSuccessTips: '提交成功！',
        deleteSuccessTips: '删除成功！',
        contentRequired:'该域不允许为空',
        contentTooLarge:'输入内容过长',
        operationSuccessTips: '操作成功！',
        seq:'序号',
        codeDup:'编码重复',
        nameDup:'名称重复',
        contentTooLargeTips:'填写的内容过长',
        operationCanceled:'操作已取消',
        uploadOptPreTips:'将文件拖到此处，或',
        uploadOptTailTips:'点击上传',
        delConfirmTips: '确认要删除当前记录吗？',
        selFile:'选择文件',
        selFileEmptyTips:'请选择要上传的文件',
        createTime:'创建时间',
        emptyTips:'该域内容不允许为空',
        contentCompLimTips:'该域内容只能由数字或者字母组成',
        operationFaled:'操作失败',
        updateTime:'最后更新时间',
        to:'到',
        selStartDateTips:'选择开始日期',
        selEndDateTips:'选择截止日期',
        creator:'创建人',
        orderState:'状态',
        stateInPending:'待处理',
        stateExecuting:'执行中',
        stateCompleted:'已完成',
        export:'导出',
        import:'导入',
        updatePassword:'修改密码',
        logout:'退出',
        oldPassword:'旧密码',
        newPassword:'新密码',
        confirmPassword:'确认密码',
        loginAccount:'登录账号',
        loginPassword:'登录密码'
    },
    route: {
        '0': '首页',
        '01': '基础信息定义',
        '0101': '商品定义',
        '0102': '场景定义',
        '0103': '单据属性',
        '0104': '详单属性',
        '0105': '单据类型',
        '02': '基础信息',
        '0201': '商品信息',
        '020101':'查询',
        '020102':'添加',
        '020103':'编辑',
        '020104':'删除',
        '020107':'同步',
        '0202': '商品图片',
        '0203': '部门管理',
        '020301':'查询',
        '020302':'添加',
        '020303':'编辑',
        '020304':'删除',
        '020305':'查看',
        '020307':'同步',
        '0204': '供应商',
        '020401':'查询',
        '020402':'添加',
        '020403':'编辑',
        '020404':'删除',
        '020405':'查看',
        '020407':'同步',
        '0205': '客户管理',
        '020501':'查询',
        '020502':'添加',
        '020503':'编辑',
        '020504':'删除',
        '020505':'查看',
        '020507':'同步',
        '03': '系统管理',
        '0301': '角色管理',
        '030301':'查询',
        '030302':'添加',
        '030303':'编辑',
        '030304':'删除',
        '030305':'查看',
        '0302': '用户管理',
        '030201':'查询',
        '030202':'添加',
        '030203':'编辑',
        '030204':'删除',
        '030208':'冻结恢复',
        '030209':'重置密码',
        '0303': '编解码设置',
        '0304': '字典管理',
        '030401':'查询',
        '030402':'添加',
        '030403':'编辑',
        '030404':'删除',
        '04': '设备',
        '0401': '版本管理',
        '040101':'查询',
        '040102':'添加',
        '040103':'编辑',
        '040104':'删除',
        '0402': '设备管理',
        '040201':'查询',
        '040204':'删除',
        '040210':'审核',
        '05': '编码中心',
        '0501': '编码记录',
        '050101':'查询',
        '050102':'添加',
        '050103':'编辑',
        '050104':'删除',
        '050105':'查看',
        '050106':'导出',
        '050111':'编码',
        '0502': '绑定记录',
        '050201':'查询',
        '050204':'删除',
        '050205':'查看',
        '050206':'导出',
        '050207':'导入',
        '06':'库存查询',
        'DC': '总仓',
        'CGRK':'采购入库',
        'CGRK01':'查询',
        'CGRK02':'添加',
        'CGRK03':'编辑',
        'CGRK04':'删除',
        'CGRK05':'撤回',
        'CGRK06':'查看',
        'CGRK07':'导出',
        'XSCK':'销售出库',
        'XSCK01':'查询',
        'XSCK02':'添加',
        'XSCK03':'编辑',
        'XSCK04':'删除',
        'XSCK05':'撤回',
        'XSCK06':'查看',
        'XSCK07':'导出',
        'QTRK':'其它入库',
        'QTRK01':'查询',
        'QTRK02':'添加',
        'QTRK03':'编辑',
        'QTRK04':'删除',
        'QTRK05':'撤回',
        'QTRK06':'查看',
        'QTRK07':'导出',
        'DCINV':'总仓盘点',
        'DCINV01':'查询',
        'DCINV04':'删除',
        'DCINV05':'撤回',
        'DCINV06':'查看',
        'DCINV07':'导出',
        'DCINV08':'更新库存',
        'PRI':'生产入库',
        'PRI01':'查询',
        'PRI02':'添加',
        'PRI03':'编辑',
        'PRI04':'删除',
        'PRI05':'撤回',
        'PRI06':'查看',
        'PRI07':'导出',
        'SHOP':'门店',
        'MDSH':'门店收货',
        'MDSH01':'查询',
        'MDSH02':'添加',
        'MDSH03':'编辑',
        'MDSH04':'删除',
        'MDSH05':'撤回',
        'MDSH06':'查看',
        'MDSH07':'导出',
        'MDTH':'门店退货',
        'MDTH01':'查询',
        'MDTH02':'添加',
        'MDTH03':'编辑',
        'MDTH04':'删除',
        'MDTH05':'撤回',
        'MDTH06':'查看',
        'MDTH07':'导出',
        'SI':'门店盘点',
        'SI01':'查询',
        'SI02':'添加',
        'SI03':'编辑',
        'SI04':'删除',
        'SI05':'撤回',
        'SI06':'查看',
        'SI07':'导出',
        'SI08':'更新库存',
        'SO00000003':'门店销售',
        'SO0000000301':'查询',
        'SO0000000302':'添加',
        'SO0000000303':'编辑',
        'SO0000000304':'删除',
        'SO0000000305':'撤回',
        'SO0000000306':'查看',
        'SO0000000307':'导出',
        'SR00000004':'零售退货',
        'SR0000000401':'查询',
        'SR0000000402':'添加',
        'SR0000000403':'编辑',
        'SR0000000404':'删除',
        'SR0000000405':'撤回',
        'SR0000000406':'查看',
        'SR0000000407':'导出',
        'BF':'标签报废',
        'BF01':'查询',
        'BF02':'添加',
        'BF03':'编辑',
        'BF04':'删除',
        'BF05':'撤回',
        'BF06':'查看',
        'BF07':'导出',
        'MDQTRK':'门店其它入库',
        'MDQTRK01':'查询',
        'MDQTRK02':'添加',
        'MDQTRK03':'编辑',
        'MDQTRK04':'删除',
        'MDQTRK05':'撤回',
        'MDQTRK06':'查看',
        'MDQTRK07':'导出'
        // 补充动态菜单...
    },

    // 定义功能
    tableDef: {
        attrDef:'属性定义',
        displayOrder:'显示顺序',
        fieldName:'字段名称',
        fieldDisName:'字段显示名',
        fieldType:'字段类型',
        fieldLength:'字段长度',
        decimalLength:'字段小数位数',
        enuItem:'枚举项',
        required: '必填',
        queryItem: '查询项',
        queryMode: '查询方式',
        fTypeVarchar:'字符串',
        fTypeInt:'整数',
        fTypeDouble:'小数',
        fTypeDatetime:'时间',
        fTypeDate:'日期',
        fTypeText:'大文本',
        rangeQuery:'范围查询',
        fuzzyMatching:'模糊匹配',
        accurateQuery:'精确查询',
        addField:'添加属性',
        editField:'编辑属性',
        delFieldConfirmTips: '确认要删除当前字段吗？',
        synProductAttr:'同步商品属性',
        synProAttrConfirmTips:'确认要同步商品信息吗？'
    },

    // 场景定义功能
    sceneDef: {
        sceneCode:'场景编号',
        sceneName:'场景名称',
        seqNum:'顺序号',
        menuImage:'菜单图片',
        selImgTips:'请选择菜单图片',
        sceneCodeEmptyTips:'场景编号不能为空',
        sceneNameEmptyTips:'场景名称不能为空',
        codeCompLimTips:'场景编号只能由数字或者字母组成',
        inputSceneSeq:'请填写场景顺序号',
        addSceneDlgTitle:'添加场景',
        updSceneDlgTitle:'修改场景',
        delConfirmMsg:'此操作将永久删除本场景, 是否继续?'
    },
    // 单据类型定义功能
    orderTypeDef: {
        typeCode:'类型编号',
        typeName:'类型名称',
        orderCodePre:'单号前缀',
        menuImage:'菜单图片',
        selImgTips:'请选择菜单图片',
        optType:'作业类别',
        permitExc:'允许超量',
        scene:'场景',
        selSceneTips:'请选择隶属场景',
        relateOrderType:'下游单据类别',
        senderAlias:'发货方别名',
        receiverAlias:'收货方别名',
        seqNum:'顺序号',
        collectTid:'采集TID',
        collectUser:'采集User',
        orderConfig:'主单配置',
        orderItemConfig:'详单配置',
        printConfig:'打印配置',
        stickerTemp:'箱贴模板',
        packingListTemp:'箱单模板',
        orderPrintTemp:'整单模板',
        typeCodeEmptyTips:'类型编号不能为空',
        typeNameEmptyTips:'类型名称不能为空',
        codeCompLimTips:'类型编号只能由数字或者字母组成',
        inputSceneSeq:'请填写场景顺序号',
        addSceneDlgTitle:'添加场景',
        updSceneDlgTitle:'修改场景',
        delConfirmMsg:'此操作将永久删除本场景, 是否继续?',
        backendList:'后台列表',
        desktopDevList:'桌面端列表',
        pdaList:'手持端列表',
        orderAttr:'主单属性',
        displayAttr:'显示属性',
        moveUp:'上移',
        moveDown:'下移',
        orderItemAttr:'详单属性',
        uploadTemplate:'上传模板',
        uploadLimTips:'只能上传xlsx/xls文件，且不超过500kb',
        addTitle:'添加单据类型',
        updTitle:'修改单据类型',
        receiveInbound:'收货入库',
        allocationInbound:'调拨入库',
        deliverOutbound:'发货出库',
        allocationOutbound:'调拨出库',
        returnAndInbound:'退货入库',
        returnAndOutbound:'退货出库',
        relocation:'移库',
        labelPurchase:'标签采购',
        labelCheck:'标签检验',
        labelInventory:'盘点',
        scrap:'报废'
    },
    //商品管理
    productManage: {
        addProduct:'添加商品信息',
        updateProduct:'修改商品信息'
    },
    // 商品图片上传功能
    productImg: {
        title:'批量上传商品图片',
        uploadTips:'请将要上传的商品图片统一打包到一个zip文件中，且zip文件内不允许包括文件夹',
        impFileName:'商品图片文件',
        uploadLimTips: '只能上传zip格式文件，且压缩包内不能包含目录',
        uploadBtnTitle:'上 传'
    },
    //部门管理
    depManage: {
        depCode:'部门编号',
        depName:'部门名称',
        superiorDep:'上级部门',
        inputQueryTips:'请输入编号或名称',
        channelCode:'渠道代码',
        addDep:'添加部门',
        updateDep:'修改部门',
        viewDep:'查看部门',
        venderCode:'供应商代码',
        venderName:'供应商名称',
        venderDesc:'供应商简介',
        addVender:'添加供应商',
        updateVender:'修改供应商',
        viewVender:'查看供应商',
        customerCode:'客户编号',
        customerName:'客户名称',
        addCustomer:'添加客户',
        updateCustomer:'修改客户',
        viewCustomer:'查看客户',
    },

    // 角色管理
    role: {
        roleName:'角色名称',
        roleType:'类型',
        menuPermissions:'菜单权限',
        dataPermissions:'数据权限',
        traceRange:'追溯范围',
        type:'角色类型',
        superAdmin:'超级管理员',
        ordinaryRole:'普通角色',
        personalPer:'本人数据',
        belDepPer:'本部门数据',
        belSubDepPer:'本部门及下级部门数据',
        allPer:'全部数据',
        addRole:'添加角色',
        updRole:'修改角色',
        viewRole:'查看角色信息',
        selDataPerTips:'请选择数据权限',
        selTraceScopeTips:'请选择追溯范围',
        roleDup:'角色名称已存在',
        selMenuPerTips:'请选择菜单权限',
        roleInfoErr:'获取角色信息时出现错误！'
    },
    // 用户管理
    user: {
        userName:'用户名称',
        userAccount:'用户账号',
        dep:'所属单位',
        role:'角色',
        accountState:'账号状态',
        frozen:'冻结',
        activated:'正常',
        recovery:'恢复',
        resetPassword:'重置密码',
        accountDup:'账号已存在',
        addUser:'添加用户',
        updateUser:'修改用户',
        frozeConfirmTips: '确定要冻结该账号吗？',
        recoveryConfirmTips: '确定要恢复该账号吗？',
        resetPasswordConfirmTips:'确定要将该用户的的登录密码恢复为默认密码吗？'
    },
    //编解码设置
    codecProtocol: {
        protocolSet:'编解码协议设置',
        protocol:'编解码协议',
        customScript:'自定义脚本',
        customProtocol:'自定义协议',
        dynamicBinding:'动态绑定',
        protocolFile:'协议文件',
        uploadLimTips: '只能上传jar格式文件，且压缩包内不能包含目录',
        encoderCode:'编码器代码',
        decoderCode:'解码器代码',
        inputCodeTips:'请输入脚本内容'
    },
    dict:{
        dicTable:'字典表',
        selDicTips:'请选择字典表',
        dicItemCode:'字典项编号',
        dicItemLabel:'字典项名称',
        itemSeq:'顺序号',
        extProperty1:'扩展属性1',
        extProperty2:'扩展属性2',
        parentItem:'上级字典项',
        addDicItem:'添加字典项',
        updateDicItem:'修改字典项',
        viewDicItem:'查看字典项',
        inputSeqTips:'请填写字典项序号',
        seqEmptyTips:'请填写字典项序号'
    },
    // 版本管理功能
    version: {
        swName:'软件名称',
        matchingDevType:'适用设备类型',
        swVersion:'版本号',
        devCode:'设备编号',
        versionDesc:'版本描述',
        swDsname:'版本文件',
        swFileSize:'文件大小',
        typeWin:'Windows桌面设备',
        typeAndroid:'安卓桌面设备',
        typePDA:'手持机',
        typeChannel:'自动化通道',
        cashierPlugin:'收银插件',
        allDev:'全部设备',
        setUpgradeFile:'设置升级文件',
        targetDevCode:'升级设备编号',
        targetDevTips:'不输入表示升级所有设备',
        upgradeFile:'升级文件',
        uploadTips:'只能上传zip格式文件',
        selDevTypeTips:'请选择设备类型'
    },
    // 设备管理
    device: {
        devCode:'设备编号',
        devType:'设备类型',
        deployAddr:'部署地点',
        lastActiveTime:'最后活跃时间',
        approvalState:'审核状态',
        pendingApproval:'待审核',
        approved:'已批准',
        refused:'已拒绝',
        agree:'通过',
        refuse:'拒绝',
        softwareVersion:'软件版本',
        approveConfirmTips:'确定要审核通过本设备吗?',
        refuseConfirmTips:'确定要拒绝通过本设备吗?'
    },

    // 编码中心
    codecCenter: {
        orderCode:'任务单号',
        orderDate:'单据日期',
        encode:'编码',
        encodingJob:'编码任务',
        encodingItem:'编码明细',
        addItem:'添加明细',
        importItem:'导入明细',
        sku:'商品条码',
        labelQTY:'标签数量',
        currentFile:'当前文件：',
        notUpload:'未上传',
        firstRowIsHead:'第一行是否表头',
        skuCover:'SKU重复覆盖',
        startRowNum:'开始行',
        endRowNum:'结束行',
        fieldName:'属性',
        fileColumnName:'文件中列名',
        uploadLimTips:'只能上传xlsx/xls文件',
        itemEmptyTips:'行目列表不能为空！',
        skuDuplicated:'SKU重复',
        bindOdCode:'绑定单号',
        bindTagList:'绑定标签列表',
        epcDuplicated:'EPC重复'
    },

    inventory:{
        productName:'商品名称',
        unit:'单位',
        qty:'数量',
        total:'合计'
    },

    // 单据
    order: {
        tagList:'标签明细',
        productList:'商品明细',
        recall:'撤回',
        updInv:'更新库存',
        itemList:'单据明细',
        aqty:'实际数量',
        difqty:'差异数'
    }

}
